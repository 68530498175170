import React from 'react'
import { NavLink } from 'react-router-dom'
import VisibilitySensor from 'react-visibility-sensor'

import './grid.scss'

export default ({ tag, children, ...props }) => {
  const TagName = tag || 'div'
  return (
    <TagName {...props} className={'grid'}>
      {children}
    </TagName>
  )
}

export const GridElement = ({
  link,
  isVisible = v => v,
  tag,
  children,
  ...props
}) => {
  const [visible, setVisible] = React.useState(false)
  const handleVisibility = v => {
    setVisible(v)
    isVisible(v)
  }
  const Wrapper = link ? NavLink : tag || 'div'
  return (
    <Wrapper {...props} className={'grid-element'} to={link}>
      <VisibilitySensor
        onChange={handleVisibility}
        partialVisibility
        offset={{ top: -120, bottom: -120 }}
        active={!visible}
      >
        {children}
      </VisibilitySensor>
    </Wrapper>
  )
}
