import React from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'

import './styles/app.scss'

import PageView, { gaInit } from './helpers/analytics'

import Header from './header'
import Contact from './contact'
import Gallery from './gallery'
import Detail from './gallery/detail'

gaInit({ trackingId: 'UA-70133049-1' })

export default () => (
  <Router>
    <PageView>
      <Header />
      <Gallery />

      <Route path={'/image/:imageName'} exact component={Detail} />
      <Route path={'/contact'} exact component={Contact} />
    </PageView>
  </Router>
)
