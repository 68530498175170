import React from 'react'
import { Link } from 'react-router-dom'

import './header.scss'

export default () => (
  <header id={'header'}>
    <h1 className={'logo'}>Bakuro</h1>
    <h2>
      fotografie
      <br />
      websites
      <br />
      vormgeving
    </h2>
    <p>
      contact@bakuro.be
      <br />
      0478 46 03 26
      <br />
      <Link to={'/contact'}>meer info…</Link>
    </p>
  </header>
)
