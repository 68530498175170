import React from 'react'
import { withRouter } from 'react-router-dom'

import './detail.scss'
import { gallery } from './gallery.json'

import Viewer from '../presentation/viewer'

export default withRouter(({ match, history }) => {
  const {
    params: { imageName },
    path
  } = match
  const prev =
    gallery[gallery.findIndex(({ filename }) => filename === imageName) - 1]
  const next =
    gallery[gallery.findIndex(({ filename }) => filename === imageName) + 1]
  const { filename, alt, caption } =
    gallery.find(({ filename }) => filename === imageName) || {}
  const detailNavigation = ({ key }) => {
    switch (key) {
      case 'Escape':
        return history.push('/')
      case 'ArrowRight':
        return next
          ? history.push(next && path.replace(':imageName', next.filename))
          : history.push('/')
      case 'ArrowLeft':
        return prev
          ? history.push(prev && path.replace(':imageName', prev.filename))
          : history.push('/')
      default:
        break
    }
  }
  React.useEffect(() => {
    const element = document.getElementsByTagName('body')[0]
    if (element) element.onkeyup = detailNavigation
  })
  return (
    <Viewer
      close={'/'}
      id={'detail'}
      tag={'article'}
      prev={prev && path.replace(':imageName', prev.filename)}
      next={next && path.replace(':imageName', next.filename)}
    >
      <picture>
        <img src={`/images/1280/${filename}.jpg`} alt={alt} />
      </picture>
      {caption ? (
        <p className={'caption'}>
          <span className={'marked'}>{caption}</span>
        </p>
      ) : null}
    </Viewer>
  )
})
