import React from 'react'

import './contact.scss'

import Viewer from '../presentation/viewer'

export default () => {
  return (
    <Viewer close={'/'} tag={'aside'} id={'contact'}>
      <h1 className={'logo'}>Bakuro</h1>
      <p>
        Johan Beckers
        <br />
        <br />
        Hof van Ranst 5/B
        <br />
        2500 Lier
        <br />
        <br />
        +32 478 46 03 26
        <br />
        contact@bakuro.be
      </p>
      <p>
        <strong>IBAN:</strong> BE06 0635 9134 9522
        <br />
        <strong>Ond. nr.</strong>: 0642.998.746
      </p>
    </Viewer>
  )
}
