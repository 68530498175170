import React from 'react'
import { withRouter } from 'react-router-dom'
import ReactGA from 'react-ga'

export const gaInit = ({ trackingId, options = {}, optIn }) => {
  if (trackingId) {
    if (optIn) window[`ga-disable-${trackingId}`] = true
    else ReactGA.initialize(trackingId, options)
  }
}

export default withRouter(({ children, location }) => {
  const [currentLocation, setCurrentLocation] = React.useState(
    location.pathname
  )
  React.useEffect(() => {
    if (location !== currentLocation && ReactGA.ga())
      ReactGA.pageview(location.pathname)
    setCurrentLocation(location.pathname)
  }, [location, currentLocation])
  return children
})
