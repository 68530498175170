import React from 'react'
import { Link } from 'react-router-dom'

import './viewer.scss'

export default ({ close, next, prev, tag, children, ...props }) => {
  const TagName = tag || 'div'
  return (
    <TagName {...props} className={'viewer'}>
      {children}
      {close ? (
        <Link to={close} className={'close'} title={'↸ Klik om terug te keren'}>
          ×
        </Link>
      ) : null}
      {prev ? (
        <Link to={prev} className={'prev'} title={'Vorige'}>
          ⇠
        </Link>
      ) : null}
      {next ? (
        <Link to={next} className={'next'} title={'Volgende'}>
          ⇢
        </Link>
      ) : null}
    </TagName>
  )
}
