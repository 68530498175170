import React from 'react'

import './gallery.scss'
import { gallery } from './gallery.json'

import Grid, { GridElement } from '../presentation/grid'

const Thumb = ({ filename, caption, alt }) => {
  const [visible, setVisible] = React.useState(false)
  const [hasLoaded, setLoaded] = React.useState(false)
  const [hasError, setError] = React.useState(false)
  return (
    <GridElement
      link={hasError ? undefined : `/image/${filename}`}
      isVisible={setVisible}
    >
      <figure>
        {visible ? (
          <img
            src={visible ? `/images/480/${filename}.jpg` : ''}
            alt={alt || caption}
            onError={() => {
              setError(true)
              setLoaded(false)
            }}
            onLoad={() => {
              setError(false)
              setLoaded(true)
            }}
            className={`${hasError ? 'error' : ''} ${
              hasLoaded ? '' : 'loading'
            }`}
          />
        ) : null}
      </figure>
    </GridElement>
  )
}

export default () => (
  <Grid tag={'main'} id={'gallery'}>
    {gallery.map((image, key) => (
      <Thumb {...image} key={key} />
    ))}
  </Grid>
)
